<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Propose for visit</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Propose for visit
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('purpose-for-visit', 'create')"
                      @click="createPurposeForVisit()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Propose for visit
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12"md="3">
                  <v-text-field
                      label="Title"
                      v-model="search.title"
                      v-on:keyup.enter="searchPurposeForVisit"
                      @input="search.title = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchPurposeForVisit"
                      @input="search.is_active = $event !== null ? $event : ''"
                      :items="status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" class="text-right">
                  <v-btn
                      @click.prevent="searchPurposeForVisit"
                      v-on:keyup.enter="searchPurposeForVisit"
                      class="btn btn-primary"

                      :loading="loading"
                  >
                    <v-icon small  outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                    <tr v-for="(item, index) in purposeForVisits" :key="index">
                      <td>
                        <a @click="editPurposeForVisit(item)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                         {{ item.title }}
                        </a>
                      </td>

                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">

                              <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('purpose-for-visit', 'edit')">
                                <a class="navi-link" @click="editPurposeForVisit(item)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('purpose-for-visit', 'delete')">
                                <a class="navi-link" @click.prevent="deletePurposeForVisit(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="purposeForVisits.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                    </tr>
                </template>
              </table>

            </div>
            <!-- table ends -->
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllPurposeForVisits"></create-and-update>
    </div>
  </v-app>
</template>

<script>
import CreateAndUpdate from "./CreateOrUpdate";
import PurposeForVisitService from "@/services/purpose-for-visit/PurposeForVisitService";

const purposeForVisit = new PurposeForVisitService();
export default {
  name: "Index",
  components: {
    CreateAndUpdate
  },
  data() {
    return{
      purposeForVisits:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      status: [
        {name: 'All', value: ''},
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search:{
        title:'',
        is_active:'',
      }
    }
  },
  mounted() {
    this.getAllPurposeForVisits();
  },
  methods: {
    createPurposeForVisit(){
      this.$refs['create-and-update'].createPurposeForVisit();
    },

    editPurposeForVisit(item){
      this.$refs['create-and-update'].editPurposeForVisit(item);
    },

    getAllPurposeForVisits(){
      this.loading = true;
      purposeForVisit
          .paginate(this.search, this.page)
          .then(response => {
            this.purposeForVisits = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchPurposeForVisit() {
      this.getAllPurposeForVisits();
    },

    deletePurposeForVisit(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            purposeForVisit
                .delete(id)
                .then((response) => {
                  this.getAllPurposeForVisits()
                  this.$snotify.success("Propose for visit Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>