<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} purpose for visit</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="purpose_for_visit.title"
                  :error="$v.purpose_for_visit.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.purpose_for_visit.title.$error">Name is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="purpose_for_visit.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.purpose_for_visit.description.$error">Description Field is required</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="purpose_for_visit.is_active"
                  :label="purpose_for_visit.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn
            v-if="checkIsAccessible('email-response', 'create') || checkIsAccessible('email-response', 'edit')"
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import PurposeForVisitService from "@/services/purpose-for-visit/PurposeForVisitService";

const purposeForVisit = new PurposeForVisitService();
export default {
  name: "CreateOrUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      errors: [],
      userSearch: null,
      purpose_for_visit: {
        title: '',
        description: '',
        is_active: true,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
    }
  },
  validations: {
    purpose_for_visit: {
      title: { required },
      description: { required },
      is_active: { required }
    }
  },

  methods: {
    closeDialog(){
      this.dialog = false;
    },
    createPurposeForVisit(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editPurposeForVisit(purpose_for_visit){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.purpose_for_visit = purpose_for_visit;
      this.getAllUser();
    },
    getAllUser() {
      user
          .getAllByUserType({name: val})
          .then((response) => {
            this.users = response.data.users
          })
          .catch(err => {
            // console.log(err)
          });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      purposeForVisit
          .create(this.purpose_for_visit)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("Created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      purposeForVisit
          .update(this.purpose_for_visit.id,this.purpose_for_visit)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.users = [];
      this.purpose_for_visit = {
        title: '',
        description: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
